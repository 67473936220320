import React, { Component, useState, useEffect } from "react";
import { Form, Button, Message, Loader, Input, Icon,
  Grid, GridColumn
} from "semantic-ui-react";
import HireTheBest from "./HireTheBest/HireTheBest";
import { Link, NavLink, useNavigation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { signin } from "../../../actions/action_auth";
import { getInitialize } from "../../../actions/action_auth";
import linkedin from "./images/linkedin.png";
import google from "./images/search.png";
import "./signin-form.css";
import Swal from "sweetalert2";
import { Tab } from "semantic-ui-react";
import { getAllClient } from "../../../actions/action_client";

import Validator from "validator";
import InlineError from "../../../messages/InlineError";
import { displayCORSWarning } from "react-pdf/dist/cjs/shared/utils";
import GettingStarted from "./ClientOnboarding/GettingStarted";
// import config from "../../../config";

export default function SigninForm() {
  const dispatch = useDispatch();
  const { talent_pool, cand_id, job_id, stage } = useParams();
  const userInfo = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("navigate wegsite", location)
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const allClients = useSelector((state)=> state.client.allClient)
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  // const [emailActivated, setEmailActivated] = useState(true)

  // useEffect(() => {
  //   dispatch(getAllClient());
  // }, [dispatch])

  const panes = [
    {
      menuItem: "Tab 1",
      render: () => (
        <Tab.Pane>
          <div
            className="signin-form"
            style={{
              borderTop: "1px solid #00000040",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "0 1rem 2rem",
            }}
          >
            {/* <Container> */}
            {/* <Grid centered columns={2}>
            <Grid.Column> */}
            <br />
            {/* {errors.email && (
              <Message negative>
                <Message.Header>Email is not activated</Message.Header>
                <p>This email is not activated.</p>
              </Message>
            )} */}
            {errors.email && (
              <Message negative>
                <Message.Header>Email is not activated</Message.Header>
                <p>This email is not activated.</p>
              </Message>
            )}
            {/* <Grid container spacing={2}> */}

            {/* </Grid> */}
            <br />
            <br />
            <br />
            <div class="ui stackable two column grid">
              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleGoogle}
                  style={{
                    textTransform: "capitalize",
                    color: "#3f3f3f",
                    background: "#fff",
                    border: "1px solid #645B5B",
                  }}
                >
                  <img
                    src={google}
                    width="13px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Google
                </Button>
              </div>

              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleLinkedin}
                  style={{
                    textTransform: "capitalize",
                    color: "#fff",
                    background: "#0073B0",
                  }}
                >
                  <img
                    src={linkedin}
                    width="13px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Linkedin
                </Button>
              </div>
            </div>
            <br />
            <div className="separator">OR</div>
            <br />
            <Form onSubmit={onSubmit}>
              <br />
              <br />
              <br />
              <Form.Field>
                <label style={{ color: "#003458" }}>Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={data.email}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />

                {/* {errors.email && <InlineError text={errors.email} />} */}
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Form.Field>
                <label style={{ color: "#003458" }}>Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* {errors.password && <InlineError text={errors.password} />} */}
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>

              <Button
                floated="right"
                type="submit"
                // loading={false}
                fluid
                style={{
                  color: "white",
                  background: "#21C8AA",
                  padding: "1rem 0",
                  // width: "90px",
                  // height: "36px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <Loader size="tiny" active inline="centered" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
            <br />
            <br />
            <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
              <NavLink to="/forgot-password">
                <h5 style={{ color: "#003458" }}>Forgot password?</h5>
              </NavLink>
            </div>
            {/* </Grid.Column>
          </Grid> */}
            {/* </Container> */}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Tab 2",
      render: () => (
        <Tab.Pane>
          <div
            className="signin-form"
            style={{
              borderTop: "1px solid #00000040",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "0 1rem 2rem",
            }}
          >
            {/* <Container> */}
            {/* <Grid centered columns={2}>
            <Grid.Column> */}
            <br />
            {errors.email && (
              <Message negative>
                <Message.Header>Email is not activated</Message.Header>
                <p>This email is not activated.</p>
              </Message>
            )}
            {/* <Grid container spacing={2}> */}

            {/* </Grid> */}
            <br />
            <br />
            <br />
            <div class="ui stackable two column grid">
              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleGoogle}
                  style={{
                    textTransform: "capitalize",
                    color: "#3f3f3f",
                    background: "#fff",
                    border: "1px solid #645B5B",
                  }}
                >
                  <img
                    src={google}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Google
                </Button>
              </div>

              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleLinkedin}
                  style={{
                    textTransform: "capitalize",
                    color: "#fff",
                    background: "#0073B0",
                  }}
                >
                  <img
                    src={linkedin}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Linkedin
                </Button>
              </div>
            </div>

            <Form onSubmit={onSubmit}>
              <br />
              <br />
              <br />
              <Form.Field>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={data.email}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* {errors.email && <InlineError text={errors.email} />} */}
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Form.Field>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />

                {/* {errors.password && <InlineError text={errors.password} />} */}
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>

              <Button
                floated="right"
                type="submit"
                // loading={false}
                fluid
                style={{
                  color: "white",
                  background: "#21C8AA",
                  padding: "1rem 0",
                  // width: "90px",
                  // height: "36px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <Loader size="tiny" active inline="centered" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
            <br />
            <br />
            <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
              <NavLink to="/forgot-password">
                <h5 style={{ color: "#003458" }}>Forgot password?</h5>
              </NavLink>
            </div>
            {/* </Grid.Column>
          </Grid> */}
            {/* </Container> */}
          </div>
        </Tab.Pane>
      ),
    },
    // { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
  ];

  const Swal = require("sweetalert2");
  const [client, setClient] = useState();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      console.log("token for OAuth recieved : ", token);
      //  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch(getInitialize()).then((res) => {
        // console.log("This result is recieved from get initialize : ", res);
        console.log("it is happing from here 222", res.data.response)
        if (res.data.response?.status === 500 || 401 || 403 || 404){
        console.log("it is happing from here 222 eeeeeeeeee uuuuu", res.data.response.status)
          localStorage.removeItem("accessToken");
 } else {
          navigate("/jobs");
        }
      });
      // Swal.fire({
      //   title: 'Login Successful',
      //   text: "Successfully Logged in.",
      //   icon: 'success',
      //   confirmButtonText: 'OK'
      // });
    } else {
      console.log("Access token not present in localStorage");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const onChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const validate = (data) => {
    const validationErrors = {};
    if (!Validator.isEmail(data.email)) {
      validationErrors.email = "Invalid email";
    }

    if (!data.password) {
      validationErrors.password = "Password is required!";
    }

    return validationErrors;
  };
  // const SERVER_URL = "http://localhost:5002"
  // const SERVER_URL = "http://165.227.4.217:5002"
  const handleGoogle = () => {
    // window.open("http://192.168.29.85:5000/api/auth/google")
    const googleSignUrl = `${process.env.REACT_APP_API_URL}/api/auth/google-login`;
    // const googleSignUrl = `${config.devwindow.location.origin}/api/auth/google-login`
    window.open(googleSignUrl, "_self");
  };

  const handleLinkedin = () => {
    // window.open("http://192.168.29.85:5000/api/auth/google")
    const googleSignUrl = `${process.env.REACT_APP_API_URL}/api/auth/linkedin-login`;
    // const googleSignUrl = `${config.devwindow.location.origin}/api/auth/linkedin-login`

    window.open(googleSignUrl, "_self");
  };

  const onSubmit = async () => {
    const validationErrors = validate(data);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const publicURL = `${window.location.origin.toString()}/${location.pathname}`;
      try {
        await dispatch(signin(data)).then((res) => {
          console.log("res hello : ", res.payload.user);
          const userRole = res.payload.user.role;
          console.log("res hello : ", userRole);
          if(userRole === 'partner' || userRole === 'partnerClient') {
            navigate('/partner-talentpool')
          }else {
            const fetchData = async () => {
              try {
                const token = localStorage.getItem("accessToken");
                const clientId = res.payload.user.clientId;
                const data = allClients.filter(client => client._id===clientId)
                setClient(data[0]);
                // const apiUrl = `https://dev-hireapi.qrata.ai/api/client/getClient/${clientId}`;
                // console.log('im here');
                // const response = await fetch(apiUrl, {
                //   headers: {
                //     Authorization: `Bearer ${token}`,
                //   },
                // });
                // console.log('im here', response);
                // const data = await response.json();
                console.log("ye dekh eeeeeeeeeee: ", data[0]);
                // setClient(data[0]);
                if (
                  data[0]?.clientName === "" ||
                  !data[0]?.clientName ||
                  data[0]?.contactNo === "" ||
                  !data[0]?.contactNo ||
                  data[0]?.countryCode === "" ||
                  !data[0]?.countryCode ||
                  data[0]?.industry === "" ||
                  !data[0]?.industry ||
                  data[0]?.designationOfContactPerson === "" ||
                  !data[0]?.designationOfContactPerson
                ) {
                  navigate("/client/about");
                } else {
                  navigate("/dashboard");
                }
                // console.log("ye dekh eeeeeeeeeee: ", data[0].contactNo)
                // if (
                //   !data[0].contactNo ||
                //   !data[0].countryCode ||
                //   !data[0].designationOfContactPerson ||
                //   !data[0].industry
                // )navigate("/client/about");
                // else navigate("/jobs");
                // navigate("/jobs");
                // setClient(data)
                // console.log("client dada: ", client);
                // return data;
              } catch (error) {
                console.error(`Error fetching one client data for id :`, error);
                return {};
              }
            };

            // console.log("client dada: ", client);
            if (window.location.pathname === "/signin") {
              // if(userRole==="client")navigate("/aboutClient")
              // else navigate("/jobs")
              if (res.payload.user.role === "client") fetchData();
              else navigate("/jobs");
              
            } else {
              // this.props.history.push(`${window.location.origin}/talent_pool/candidate/${this.props.cand_id}/bio`)
              // window.location.href = publicURL;
              // navigate(location.pathname.split('sales')[0])
              // if(location.pathname.includes('customerSales')){

              //   navigate(location.pathname)
              // }
              navigate(location.pathname.split('customerSales')[0])
              // navigate(location.pathname.includes('customerSales') ? location.pathname : location.pathname.split('sales')[0]);
            }
          }
        });

        console.log("this is client: ", client);

        // `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public` ? `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/` : navigate("/jobs")
        // if(`${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public`){
        //    console.log("hey brooooooo")
        // }
        // else{

        //   console.log("hey brooooooo2")
        // }
        // if(`${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public`){
        //   console.log("public link")
        //   `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio`
        // }
        // else{
        // console.log("else state")
        // navigate("/jobs");
        // }
      } catch (error) {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data;
          if (responseErrors.error === "Email not provided!") {
            setErrors({ email: responseErrors.error });
          } else if (responseErrors.error === "This email does not exist") {
            setErrors({ email: responseErrors.error });
          } else if (responseErrors.error === "User is not activated") {
            setErrors({ email: responseErrors.error });
          } else {
            setErrors({ password: responseErrors.error });
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const urlEndsWithSales = () => {
    console.log("sales check", window.location.pathname.endsWith('/sales') || window.location.pathname.endsWith('/public') ? true : false)
    return window.location.pathname.endsWith('/sales') || window.location.pathname.endsWith('/public') || window.location.pathname.endsWith('/customerSales') ? true : false;
  };
  const temp = urlEndsWithSales()

  return (
    <>
    {temp===false ?
    <Grid stackable relaxed container columns='equal'>
      <GridColumn width={8} style={{padding:'2.5rem 4rem 2.5rem 0rem'}} className="first-grid-column">
        <HireTheBest />
      </GridColumn>
      <GridColumn width={8} >
        <div style={{zIndex: '9999'}} className="signin-form">

        <GettingStarted />
        </div>
      </GridColumn>
    </Grid>
      // <Grid stackable relaxed container columns='equal'>
      //  <GridColumn width={8} style={{padding:'2.5rem 4rem 2.5rem 0rem'}} className="first-grid-column">
      //  <HireTheBest />
      //  </GridColumn>
      //  <GridColumn width={8}>
      //  <div
      //    className="signin-form"
      //    style={{
      //      borderTop: "1px solid #00000040",
      //      borderRadius: "10px",
      //      boxShadow: "0px 4px 4px 0px #00000040",
      //      marginTop: "10px",
      //      padding: "0 2rem 2rem",
      //    }}
      //  >
      //    <br />
      //    {errors.email === "User is not activated" && (
      //      <Message negative>
      //        <Message.Header>Email is not activated</Message.Header>
      //        <p>This email is not activated.</p>
      //      </Message>
      //    )}
      //    <h5 className="page-title">Login</h5>
      //    <div
      //      class="ui stackable two column grid"
      //      style={{
      //        width: "100%",
      //        display: "flex",
      //        justifyContent: "space-around !important",
      //        alignItems: "center",
      //      }}
      //    >
      //      <div class="column">
      //        <Button
      //          variant="contained"
      //          fullWidth
      //          size="large"
      //          onClick={handleGoogle}
      //          className="google-linked-button"
      //          style={{
      //            textTransform: "capitalize",
      //            color: "#3f3f3f",
      //            background: "#fff",
      //            border: "1px solid #645B5B",
      //          }}
      //        >
      //          <img src={google} width="13px" style={{ marginRight: "10px" }} />
      //          Login via Google
      //        </Button>
      //      </div>
   
      //      <div class="column">
      //        <Button
      //          variant="contained"
      //          fullWidth
      //          size="large"
      //          onClick={handleLinkedin}
      //          className="google-linked-button"
      //          style={{
      //            textTransform: "capitalize",
      //            color: "#fff",
      //            background: "#0073B0",
      //          }}
      //        >
      //          <img src={linkedin} width="13px" style={{ marginRight: "10px" }} />
      //          Login via Linkedin
      //        </Button>
      //      </div>
      //    </div>
      //    <br />
      //    <div className="separator">OR</div>
   
      //    <Form onSubmit={onSubmit}>
      //      <br />
      //      <Form.Field>
      //        <input
      //          type="email"
      //          name="email"
      //          id="email"
      //          placeholder="Email"
      //          value={data.email}
      //          onChange={onChange}
      //          required
      //          style={{ padding: "1.2rem" }}
      //        />
      //        {errors.email && <InlineError text={errors.email} />}
      //      </Form.Field>
      //      <Form.Field>
      //        <Input
      //          icon
      //          placeholder="Search..."
      //          style={{
      //            border: "none",
      //          }}
      //        >
      //          <input
      //            type={visible ? "text" : "password"}
      //            name="password"
      //            id="password"
      //            placeholder="Password"
      //            value={data.password}
      //            onChange={onChange}
      //            required
      //          />
      //          <Icon
      //            name={visible ? "eye slash" : "eye"}
      //            onClick={() => setVisible((visible) => !visible)}
      //            // circular
      //            link
      //          />
      //        </Input>
      //        {/* {errors.password && <InlineError text={errors.password} />} */}
      //        {errors.password && <InlineError text={errors.password} />}
      //      </Form.Field>
   
      //      <Button
      //        floated="right"
      //        type="submit"
      //        // loading={false}
      //        fluid
      //        style={{
      //          color: "white",
      //          background: "#21C8AA",
      //          padding: "1rem 0",
      //          // width: "90px",
      //          // height: "36px",
      //        }}
      //        disabled={loading}
      //      >
      //        {loading ? <Loader size="tiny" active inline="centered" /> : "Submit"}
      //      </Button>
      //    </Form>
      //    <br />
      //    <br />
   
      //    <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
      //      <NavLink to="/forgot-password">
      //        <h5 style={{ color: "#003458" }}>Forgot password?</h5>
      //      </NavLink>
      //    </div>
      //    <div class="help-text" style={{textAlign: 'center'}}>
      //      <p className="text-before-link">
      //        Don't have an account?{" "}
      //        <Link to="/signup" className="not-this-link">
      //          Sign up
      //        </Link>
      //      </p>
      //    </div>
      //  </div>
      //  </GridColumn>
      //  </Grid>
      : 
      <div
         className="signin-form"
         style={{
           borderTop: "1px solid #00000040",
           borderRadius: "10px",
           boxShadow: "0px 4px 4px 0px #00000040",
           marginTop: "10px",
           padding: "0 2rem 2rem",
         }}
       >
         <br />
         {errors.email === "User is not activated" && (
           <Message negative>
             <Message.Header>Email is not activated</Message.Header>
             <p>This email is not activated.</p>
           </Message>
         )}
         <h5 className="page-title">Login</h5>
         <div
           class="ui stackable two column grid"
           style={{
             width: "100%",
             display: "flex",
             justifyContent: "space-around !important",
             alignItems: "center",
           }}
         >
           <div class="column">
             <Button
               variant="contained"
               fullWidth
               size="large"
               onClick={handleGoogle}
               className="google-linked-button"
               style={{
                 textTransform: "capitalize",
                 color: "#3f3f3f",
                 background: "#fff",
                 border: "1px solid #645B5B",
               }}
             >
               <img src={google} width="13px" style={{ marginRight: "10px" }} />
               Login via Google
             </Button>
           </div>
   
           <div class="column">
             <Button
               variant="contained"
               fullWidth
               size="large"
               onClick={handleLinkedin}
               className="google-linked-button"
               style={{
                 textTransform: "capitalize",
                 color: "#fff",
                 background: "#0073B0",
               }}
             >
               <img src={linkedin} width="13px" style={{ marginRight: "10px" }} />
               Login via Linkedin
             </Button>
           </div>
         </div>
         <br />
         <div className="separator">OR</div>
   
         <Form onSubmit={onSubmit}>
           <br />
           <Form.Field>
             <input
               type="email"
               name="email"
               id="email"
               placeholder="Email"
               value={data.email}
               onChange={onChange}
               required
               style={{ padding: "1.2rem" }}
             />
             {errors.email && <InlineError text={errors.email} />}
           </Form.Field>
           <Form.Field>
             <Input
               icon
               placeholder="Search..."
               style={{
                 border: "none",
               }}
             >
               <input
                 type={visible ? "text" : "password"}
                 name="password"
                 id="password"
                 placeholder="Password"
                 value={data.password}
                 onChange={onChange}
                 required
               />
               <Icon
                 name={visible ? "eye slash" : "eye"}
                 onClick={() => setVisible((visible) => !visible)}
                 // circular
                 link
               />
             </Input>
             {/* {errors.password && <InlineError text={errors.password} />} */}
             {errors.password && <InlineError text={errors.password} />}
           </Form.Field>
   
           <Button
             floated="right"
             type="submit"
             // loading={false}
             fluid
             style={{
               color: "white",
               background: "#21C8AA",
               padding: "1rem 0",
               // width: "90px",
               // height: "36px",
             }}
             disabled={loading}
           >
             {loading ? <Loader size="tiny" active inline="centered" /> : "Submit"}
           </Button>
         </Form>
         <br />
         <br />
   
         <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
           <NavLink to="/forgot-password">
             <h5 style={{ color: "#003458" }}>Forgot password?</h5>
           </NavLink>
         </div>
         <div class="help-text" style={{textAlign: 'center'}}>
           <p className="text-before-link">
             Don't have an account?{" "}
             <Link to="/signup" className="not-this-link">
               Sign up
             </Link>
           </p>
         </div>
       </div>
    }
    </>
  );
}
