import axios from "./axiosInstance";
import { getHeader } from "./../types.js";
import { data } from "jquery";

export default {
  application: {
    fetchCandidateByJobIdApi: (job_id, app_id) =>
      axios
        .get(`/api/application/fetchTalent/${job_id}/${app_id}`, {
          headers: getHeader(),
          headers: getHeader(),
        })
        .then((res) => res.data),
    savingNotesApi: (note_params, note) =>
      axios
        .post(
          `/api/application/job/${note_params.job_id}/application/${note_params.app_id}/note`,
          {
            recruiterID: note_params.user_id,
            note: note,
            note_params: note_params,
          },
          { headers: getHeader() }
        )
        .then((res) => res.data),
    editingNotesApi: (note_params, comment) =>
      axios
        .put(
          `/api/application/job/${note_params.job_id}/application/${note_params.app_id}/note/${note_params.note_id}/edit`,
          { comment: comment },
          { headers: getHeader() }
        )
        .then((res) => res.data),
    deletingNotesApi: (note_params) =>
      axios
        .delete(
          `/api/application/job/${note_params.job_id}/application/${note_params.app_id}/note/${note_params.note_id}/delete`,
          { headers: getHeader() }
        )
        .then((res) => res.data),
    fetchFilterContentApi: (id) =>
      axios
        .get(`/api/application/filter/${id}`, { headers: getHeader() })
        .then((res) => res.data),
    filterCandidateApplicationsApi: (data, id) =>
      axios
        .post(`/api/application/filter/canidate/${id}`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    fetchCandidateTalentPoolApi: (id) =>
      axios
        .get(`/api/talentpool/candidate/${id}`, { headers: getHeader() })
        .then(
          (res) => res.data
          //  {
          // console.log("#######################################3333333",res.data)
          // res.data
          // }
        ),
    saveDocumentNowApi: (data) =>
      axios
        .post(`/api/application/document/${data.job_id}/${data.app_id}`, data)
        .then((res) => console.log("API RES", res)),
    setInterviewApi: (job_id, cand_id, user_id, data) =>
      axios
        .post(
          `/api/application/interview/${job_id}/${cand_id}/${user_id}`,
          data,
          { headers: getHeader() }
        )
        .then((res) => res.data),
    cancelInterviewApi: (int_id, job_id, cand_id) =>
      axios
        .post(
          `/api/application/cancelinterview/${int_id}/${job_id}/${cand_id}`,
          { headers: getHeader() }
        )
        .then((res) => res.data),
    deleteInterviewApi: (int_id, job_id, cand_id) =>
      axios
        .delete(
          `/api/application/deleteinterview/${int_id}/${job_id}//${cand_id}`,
          { headers: getHeader() }
        )
        .then((res) => res.data),
    adminUpdateTalentApi: (data, talentId) =>
      axios
        .put(`/api/talent/${talentId}`, data, { headers: getHeader() })
        .then((res) => res.data),

    // RESUME UPDATE API
    resumeUploadApi: (resume, talentId) => {
      console.log("-----------------------------------resume upload", resume);

      let headers = {
        Accept: "multipart/form-data",
      };

      const fd = new FormData();
      fd.append("resume", resume);
      return axios
        .post(`/api/talent/${talentId}/resume/upload`, fd, headers)
        .then((res) => res.data);
    },
    certificateUploadApi: (data, talentId) => {
      console.log("++++++++commanDocumetsUploadApi++++++++++++++", data);
      let headers = {
        Accept: "multipart/form-data",
      };
      const fd = new FormData();
      fd.append("file", data.certificate_file);
      fd.append("title", data.certificate_title);
      let token = "";
      return axios
        .post(`/api/talent/certificate/${talentId}/upload`, fd, headers, {
          onUploadProgress: (progressEvent) => {
            console.log(
              "Upload Progress: " +
                Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                "%"
            );
          },
        })
        .then((res) => res.data);
    },
    deleteCertificateApi: (data) => {
      const talentId = data.talent_id;
      let token = "";
      let headers = "";
      if (window.localStorage.token) {
        token = window.localStorage.token;
        token = "Bearer " + token;
        headers = {
          Accept: "application/json",
          Authorization: token,
        };
      }
      console.log(data, "api ===========headers=========> ", getHeader());
      return axios
        .delete(
          `/api/talent/certificate/${talentId}/upload`,
          { data },
          {
            headers: getHeader(),
          }
        )
        .then((res) => res.data);
    },
    profilePhotoUploadApi: async (data, talentId) => {
      console.log("++++++++profilephoto  upload ++++++++++++++", data);
      let headers = {
        // Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      };
      const fd = new FormData();
      fd.append("file", data);
      let token = "";
      const res = await axios.post(
        `/api/talent/profilephoto/${talentId}/upload`,
        fd,
        headers,
        {
          onUploadProgress: (progressEvent_1) => {
            console.log(
              "Upload Progress: " +
                Math.round(
                  (progressEvent_1.loaded / progressEvent_1.total) * 100
                ) +
                "%"
            );
          },
        }
      );
      return res.data;
    },
    

    candidateDocumentUploadApi: (data, talentId) => {
      console.log("++++++++commanDocumetsUploadApi++++++++++++++", data);
      let headers = {
        Accept: "multipart/form-data",
      };
      const fd = new FormData();
      fd.append("file", data.file);
      fd.append("title", data.title);
      let token = "";
      return axios
        .post(`/api/talent/candidatedocs/${talentId}/upload`, fd, headers, {
          onUploadProgress: (progressEvent) => {
            console.log(
              "Upload Progress: " +
                Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                "%"
            );
          },
        })
        .then((res) => res.data);
    },
    deleteCandidateDocumentApi: (data) => {
      const talentId = data.talent_id;
      let token = "";
      let headers = "";
      if (window.localStorage.token) {
        token = window.localStorage.token;
        token = "Bearer " + token;
        headers = {
          Accept: "application/json",
          Authorization: token,
        };
      }
      console.log(data, "api ===========headers=========> ", getHeader());
      return axios
        .delete(
          `/api/talent/candidatedocs/${talentId}/upload`,
          { data },
          {
            headers: getHeader(),
          }
        )
        .then((res) => res.data);
    },

    commanDocumetsUploadApi: (data, talentId) => {
      console.log("++++++++commanDocumetsUploadApi++++++++++++++", data);
      // return "hello"
      //  let headers = '';
      let headers = {
        Accept: "multipart/form-data",
      };

      const fd = new FormData();
      fd.append("file", data.file);
      fd.append("title", data.title);
      fd.append("link", data.link);
      fd.append("description", data.description);
      let token = "";

      //   if (localStorage.token) {
      //     token = localStorage.token
      //     token = 'Bearer ' + token;
      //     headers = {
      //       'Accept': 'multipart/form-data',
      //       'Authorization': token
      //     }
      // }

      return axios
        .post(`/api/talent/docs/${talentId}/documents/upload`, fd, headers, {
          onUploadProgress: (progressEvent) => {
            console.log(
              "Upload Progress: " +
                Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                "%"
            );
          },
        })
        .then((res) => res.data);
    },

    // editWorkSamplesApi: async (data, talentId) => {
    //   console.log("++++++++editWorkSamplesApi++++++++++++++", data);
    //   // return "hello"
    //   //  let headers = '';
    //   let headers = {
    //     Accept: "multipart/form-data",
    //   };

    //   const fd = new FormData();
    //   fd.append("file", data.file);
    //   fd.append("title", data.title);
    //   fd.append("link", data.link);
    //   fd.append("description", data.description);
    //   fd.append("workSample_id", data.workSample_id);
    //   fd.append("talent_id", talentId);

    //   let token = "";

    //     if (localStorage.token) {
    //       token = localStorage.token
    //       token = 'Bearer ' + token;
    //       headers = {
    //         'Accept': 'multipart/form-data',
    //         'Authorization': token
    //       }
    //   }

    //   return axios
    //     .put(`/api/talent/docs/${talentId}/documents/upload`, fd, headers, {
    //       onUploadProgress: (progressEvent) => {
    //         console.log(
    //           "Upload Progress: " +
    //             Math.round((progressEvent.loaded / progressEvent.total) * 100) +
    //             "%"
    //         );
    //       },
    //     })
    //     .then((res) => res.data);
    // },

    editWorkSamplesApi: async (data, talentId) => {
      console.log("++++++++editWorkSamplesApi++++++++++++++", data);
    
      let headers = {
        Accept: "multipart/form-data",
      };
    
      const fd = new FormData();
      fd.append("file", data.file);
      fd.append("title", data.title);
      fd.append("link", data.link);
      fd.append("description", data.description);
      fd.append("workSample_id", data.workSample_id);
      fd.append("talent_id", talentId);
    
    //   let token = localStorage.getItem('accessToken'); // Use getItem for localStorage access
    // console.log("token from localStorage : ", token)
    //   if (token) {
    //     token = 'Bearer ' + token;
    //     headers = {
    //       'Accept': 'multipart/form-data',
    //       'Authorization': token
    //     };
    //   } else {
    //     // Handle the case where no token is found (e.g., redirect to login)
    //     console.error("No token found in localStorage");
    //     return;
    //   }
    
      return axios
        .put(`/api/talent/docs/${talentId}/documents/upload`, fd, headers, {
          onUploadProgress: (progressEvent) => {
            console.log(
              "Upload Progress: " +
                Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                "%"
            );
          },
        })
        .then((res) => res.data);
    },


    deleteWorkSampleApi: (data) => {
      const talentId = data.talent_id;
      let token = "";
      let headers = "";
      if (window.localStorage.token) {
        token = window.localStorage.token;
        token = "Bearer " + token;
        headers = {
          Accept: "application/json",
          Authorization: token,
        };
      }
      console.log(data, "api ===========headers=========> ", getHeader());
      return axios
        .delete(
          `/api/talent/docs/${talentId}/documents/upload`,
          { data },
          {
            headers: getHeader(),
          }
        )
        .then((res) => res.data);
    },


   addProjectApi: (data, talentId) => {
      const token = localStorage.getItem("accessToken")
      let headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      };
      return axios
        .post(`/api/talent/${talentId}/projects`, data, headers)
        .then((res) => res.data);
    },
    editProjectsApi: async (data, talentId) => {
      console.log("++++++++editWorkSamplesApi++++++++++++++", data);
    
      let headers = {
        Accept: "application/json",
      };
    
      // const fd = new FormData();
      // fd.append("file", data.file);
      // fd.append("title", data.title);
      // fd.append("link", data.link);
      // fd.append("description", data.description);
      // fd.append("workSample_id", data.workSample_id);
      // fd.append("talent_id", talentId);
    
      let token = localStorage.getItem('accessToken'); // Use getItem for localStorage access
    console.log("token from localStorage : ", token)
      if (token) {
        token = 'Bearer ' + token;
        headers = {
          'Accept': 'application/json',
          'Authorization': token
        };
      } else {
        console.error("No token found in localStorage");
        return;
      }
      return axios
        .put(`/api/talent/${talentId}/projects/${data.project_id}`, {data:data}, headers)
        .then((res) => res.data);
    },   
    deleteProjectApi: (data) => {
      console.log("see data for delete : ", data)
      const talentId = data.talent_id;
      let token = "";
      let headers = "";
      if (window.localStorage.token) {
        token = window.localStorage.token;
        token = "Bearer " + token;
        headers = {
          Accept: "application/json",
          Authorization: token,
        };
      }
      console.log(data, "api ===========headers=========> ", getHeader());
      return axios
        .delete(
          `/api/talent/${talentId}/projects/${data.project_id}`,
          { data },
          {
            headers: getHeader(),
          }
        )
        .then((res) => res.data);
    },
    saveQrataNotesApi: (data, talentId) => {
      console.log("api ====================> ", data);
      return axios
        .post(`/api/candidate/${talentId}/qrata_notes`, data)
        .then((res) => res.data);
    },
    editQrataNotesApi: (data, talentId, qrataNotesId) =>
      axios
        .put(`/api/candidate/${talentId}/qrata_notes/${qrataNotesId}`, data)
        .then((res) => res.data),
    deleteQrataNotesApi: (data, talentId, qrataNotesId) => {
      console.log("data from api", data);
      return axios
        .delete(`/api/candidate/${talentId}/qrata_notes/${qrataNotesId}`, {
          data,
        })
        .then((res) => res.data);
    },
    addReferenceInfoApi: (data, talentId) =>
      axios
        .post(`/api/candidate/${talentId}/refInfo`, data)
        .then((res) => res.data),
    takeReferenceInfoApi: (data, talentId, refId) =>
      axios
        .put(`/api/candidate/${talentId}/refInfo/${refId}`, data)
        .then((res) => res.data),
    moveCandidateStageApi: (job_id, app_id, stage) =>
      axios
        .put(`/api/job/${job_id}/application/${app_id}`, stage, {
          headers: getHeader(),
        })
        .then((res) => res.data),
    addCandidateFeedbackApi: (data, talentId) =>
      axios
        .post(`/api/candidate/${talentId}/analyticsfeedback`, data)
        .then((res) => res.data),
    editWorkExperienceApi: (data, talentId, workExperienceId) =>
      axios
        .put(
          `/api/talent/${talentId}/workexperience/${workExperienceId}`,
          data,
          talentId,
          workExperienceId
        )
        .then((res) => res.data),

    editPotentialAnalysisApi: (data, talentId) =>
      axios
        .post(`/api/talentpool/${talentId}/potential`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data)
        .catch(function (error) {
          console.log(error.response.status); // 401
          console.log(error.response.data.error); //Please Authenticate or whatever returned from server
          // if(error.response.status==401){
          //   window.location.href = `${window.location.origin.toString()}/signin`;
          // }
        }),

    editEducationApi: (data, talentId, educationId) =>
      axios
        .put(
          `/api/talent/${talentId}/education/${educationId}`,
          data,
          talentId,
          educationId
        )
        .then((res) => res.data),

    curatedProfileApi: (data, talentId) =>
      axios
        .put(`/api/candidate/${talentId}/isCurated`, data)
        .then((res) => res.data),

    qaProfileApi: (data, talentId) =>
      axios
        .put(`/api/candidate/${talentId}/isQA`, data)
        .then((res) => res.data),

    addScoreCard: (data) =>
      axios
        .post(`/api/talentpool/addscorecard-template`, data)
        .then((res) => res.data),

    getScoreCard: (data) =>
      axios
        .get(`/api/talentpool/addscorecard-template`, data)
        .then((res) => res.data),

    updateScoreCard: (data, Id) =>
      axios
        .put(`/api/talentpool/updatescorecard-template/${Id}`, data)
        .then((res) => res.data),

    personalityTraits: (data, Id) =>
      axios.get(`/api/talent/sw/${Id}`, data).then((res) => res.data),

    talentGPT: (data, Id) =>
      axios.get(`/api/talent/gptInsights/${Id}`).then((res) => res.data),

    curationData: async (jobId, candId) =>
      axios.get(`/api/curation/${jobId}/${candId}`).then((res) => res?.data),

    qrataFit: (jobId, candId, qratafit) =>
      axios
        .post(`/api/curation/qratafit/${jobId}/${candId}`, { qratafit })
        .then((res) => res.data),
    changeSubStatusApi: (jobId, appId, data) =>
      axios
        .post(`/api/application/${jobId}/sub-status/${appId}`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),

    getChatedUser: (email) =>
      axios
        .get(`https://chatapi.qrata.ai/user?email=${email}`)
        .then((res) => res),

    chatsData: (senderEmail, receiverEmail) =>
      axios
        .post(`https://chatapi.qrata.ai/chats`, { senderEmail, receiverEmail })
        .then((res) => res.data),

    internalNotes: (marketId, applicantId, data) =>
      axios
        // .post(`/api/candidate/${candId}/notes`, data, { headers: getHeader() })
        .post(`/api/marketInsights/${marketId}/applicant/${applicantId}`, data, { headers: getHeader() })
        .then((res) => res.data),

    deleteInternalNotes: (candId, notesId) =>
      axios
        .delete(`/api/candidate/${candId}/notes/${notesId}`, {
          headers: getHeader(),
        })
        .then((res) => res.data),

    clientNotes: (jobId, appId, data) =>
      axios
        .post(`/api/application/job/${jobId}/application/${appId}/note`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),

    deleteClientNotes: (jobId, appId, noteId) =>
      axios
        .delete(
          `/api/application/job/${jobId}/application/${appId}/note/${noteId}/delete`,
          { headers: getHeader() }
        )
        .then((res) => res.data),

    changeSubStatusApi: (jobId, appId, data) =>
      axios
        .post(`/api/application/${jobId}/sub-status/${appId}`, data, {
          headers: getHeader(),
        })
        .then((res) => res.data),

    stabilityRating: (candId) => {
      return axios
        .get(`/api/talent/stabilityRatings/${candId}`)
        .then((res) => res.data)
        .catch((error) => {
          console.log(error.message);
        });
    },

    interviewList: async () => {
      return await axios.get(`/api/schedule/getEvents`).then((res) => res.data);
    },
    unlockedTalent: async (id) => {
      return await axios
        .get(`/api/unlock/client/${id}`)
        .then((res) => res.data);
    },
    marketIntelList: async (filter) => {
      return await axios
        // .get(`/api/marketInsights/allMarketInsights`)
        .post(`/api/marketInsights/allMarketInsights/filter`, filter)
        .then((res) => res.data.MarketInsights);
    },
    mappedCandidatesList: async (filters = {}, page, size) => {
      return await axios
        .post(`/api/candidate/allCandidate`, filters, {params: {page, size}})
        .then((res) => res.data);
    },
    mappedCandidatesListByID: async (id) => {
      return await axios
        // .get(`/api/candidate/allCandidates/${id}`)
        .post(`/api/candidate/allCandidates/${id}`, {
          name:"",
          email:"",
          designation:"",
          company:""
        },
        { headers: getHeader() }
      )
        .then((res) => res.data);
    },
    requestSubmitApi: async (jobId, item, talentId, data) => {
      return await axios
        .put(
          `/api/job/request/job/${jobId}/item/${item}/talent/${talentId}`,
          data,
          { headers: getHeader() }
        )
        .then((res) => res.data);
    },

    saveTalentScorecard: async (summary, scores, jobId, talentId) => {
      console.log(summary, talentId, scores, jobId, "dskjhfbhasdj 3");
      return await axios
        .post(`/api/talentscore`, {
          summary,
          scores,
          jobId,
          talentId,
        })
        .then((res) => res.data);
    },

    talentScorecard: async (talentId, jobId) => {
      console.log(jobId, talentId, "dsflk jbn");

      return await axios
        .post(`/api/talentscore/get`, { talentId, jobId })
        .then((res) => res.data);
    },
  },
};
